import {
    treedevelopingurl,
    furukawaroiprecon,
    nostracasaurl,
    guitarchordsplaystoreurl,
    placeHolderUrl,
} from './paths'
import {
    owlmonicon,
    googleplayicon,
    appstoreicon,
    treedevelopingicon,
    furukawatechnicalproposalconfig,
    guitarchordsicon,
    mainlogonostracasa,
    furukawaroipreconchart,
} from '../img/appimages'

export const portfolioArray = [
    {
        viewStatus: true,
        category: 'Website', 
        projectName: 'tree developing', 
        status: 'Concluído',
        link: treedevelopingurl, 
        image: treedevelopingicon, 
        description: 'Site institucional para apresentação da empresa tree developing', 
        mobile: false,
        playStoreImage: googleplayicon,
        playStoreLink: null, 
        appStoreImage: appstoreicon,
        appStoreLink: null,
    },
    {
        viewStatus: false,
        category: 'WebApp', 
        projectName: 'Furukawa Retorno de Investimento Precon', 
        status: 'Concluído',
        link: furukawaroiprecon, 
        image: furukawaroipreconchart, 
        description: 'Esta ferramenta é a melhor maneira de apresentar os benefícios das soluções FTTx pré-conectorizadas aos clientes. Este wepapp já ajudou a fechar negócios importantes e aumentar as vendas de soluções FTTx pré-conectorizadas.', 
        mobile: false,
        playStoreImage: googleplayicon,
        playStoreLink: null, 
        appStoreImage: appstoreicon,
        appStoreLink: null,
    },
    {
        viewStatus: false,
        category: 'Website', 
        projectName: 'Nostra Casa Hamburgueria', 
        status: 'Andamento',
        link: nostracasaurl, 
        image: mainlogonostracasa, 
        description: 'Site de apresentação da melhor hamburgueria da região Oeste do Paraná, localizada em Matelândia-PR.', 
        mobile: false,
        playStoreImage: googleplayicon,
        playStoreLink: null, 
        appStoreImage: appstoreicon,
        appStoreLink: null,
    },
    {
        viewStatus: false,
        category: 'WebApp', 
        projectName: 'Proposta Técnico-Comercial', 
        status: 'Concluído',
        link: placeHolderUrl, 
        image: furukawatechnicalproposalconfig, 
        description: 'Aplicação para geração de propostas/documentos técnicos que podem sem utilizados pelos responsáveis comerciais na tratativa com o cliente.', 
        mobile: false,
        playStoreImage: googleplayicon,
        playStoreLink: null, 
        appStoreImage: appstoreicon,
        appStoreLink: null,
    },
    {
        viewStatus: false,
        category: 'Automação', 
        projectName: 'Proposta Técnico-Comercial', 
        status: 'Concluído',
        link: placeHolderUrl, 
        image: furukawatechnicalproposalconfig, 
        description: 'Aplicação para geração de propostas/documentos técnicos que podem sem utilizados pelos responsáveis comerciais na tratativa com o cliente.', 
        mobile: false,
        playStoreImage: googleplayicon,
        playStoreLink: null, 
        appStoreImage: appstoreicon,
        appStoreLink: null,
    },

];

export const appPortfolioArray = [
    {
        viewStatus: true,
        category: 'App', 
        projectName: 'Guitar Chords', 
        status: 'Concluído',
        link: guitarchordsplaystoreurl, 
        image: guitarchordsicon, 
        description: 'Biblioteca de acordes simples para violão e guitarra', 
        mobile: true,
        playStoreImage: googleplayicon,
        playStoreLink: guitarchordsplaystoreurl, 
        appStoreImage: appstoreicon,
        appStoreLink: null,
    },
    {
        viewStatus: true,
        category: 'App', 
        projectName: 'OwlMom', 
        status: 'Andamento',
        link: placeHolderUrl, 
        image: owlmonicon, 
        description: 'Aplicativo mobile para monitoramento de informações de bebê. Conta com cadastro de conta via e-mail, monitor de sono, fraldas e amamentação.', 
        mobile: true,
        playStoreImage: googleplayicon,
        playStoreLink: guitarchordsplaystoreurl, 
        appStoreImage: appstoreicon,
        appStoreLink: null,
    },
];